/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateConfig
 */
export interface TemplateConfig {
    /**
     * 
     * @type {number}
     * @memberof TemplateConfig
     */
    repetitions?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateConfig
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateConfig
     */
    time?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateConfig
     */
    initTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateConfig
     */
    breakTime?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateConfig
     */
    useTimer?: boolean;
}

/**
 * Check if a given object implements the TemplateConfig interface.
 */
export function instanceOfTemplateConfig(value: object): value is TemplateConfig {
    return true;
}

export function TemplateConfigFromJSON(json: any): TemplateConfig {
    return TemplateConfigFromJSONTyped(json, false);
}

export function TemplateConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'repetitions': json['repetitions'] == null ? undefined : json['repetitions'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'time': json['time'] == null ? undefined : json['time'],
        'initTime': json['initTime'] == null ? undefined : json['initTime'],
        'breakTime': json['breakTime'] == null ? undefined : json['breakTime'],
        'useTimer': json['useTimer'] == null ? undefined : json['useTimer'],
    };
}

export function TemplateConfigToJSON(json: any): TemplateConfig {
    return TemplateConfigToJSONTyped(json, false);
}

export function TemplateConfigToJSONTyped(value?: TemplateConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'repetitions': value['repetitions'],
        'weight': value['weight'],
        'time': value['time'],
        'initTime': value['initTime'],
        'breakTime': value['breakTime'],
        'useTimer': value['useTimer'],
    };
}

