import { ExerciseDTO } from '../generated';

enum ExerciseValidationErrors {
  NoMuscleTags = 'No Muscle Tag selected',
  NoMainBodyZone = 'A primary Body Zone must be selected.',
  TitleMustNotBeEmpty = 'Title must not be empty',
}

export const validateExercise = (exercise: ExerciseDTO) => {
  let errors: Array<string> = [];

  if (exercise.muscleIntensities.length === 0) errors = [...errors, ExerciseValidationErrors.NoMuscleTags];
  else errors = errors.filter((e) => e !== ExerciseValidationErrors.NoMuscleTags);

  if (!exercise.mainBodyZoneId) errors.push(ExerciseValidationErrors.NoMainBodyZone);

  if (!exercise.name.trim()) errors.push(ExerciseValidationErrors.TitleMustNotBeEmpty);

  return errors;
};
