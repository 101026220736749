import { useEffect, useState } from 'react';
import { SetDTO } from '../generated';
import { getLocalSetList } from '../utils/api-helpers';

export function useSetList() {
  const [setList, setSetList] = useState<Array<SetDTO>>();

  useEffect(() => {
    async function initialize() {
      if (!setList) {
        const sl = await getLocalSetList();
        setSetList(sl);
      }
    }
    initialize();
  }, [setList]);

  return setList;
}
