import { useEffect, useState } from 'react';
import { setApi } from '../generated/clients';
import { SetValidationDTO } from '../generated';

export const useValidateSet = (setId?: string): [set: SetValidationDTO | undefined, error: string | undefined, validate: () => void] => {
  const [setValidationResult, setSetValidationResult] = useState<SetValidationDTO>();
  const [error, setError] = useState<string | undefined>();

  async function validate(setId?: string) {
    try {
      if (setId) {
        const validationResult = await setApi.setValidate({ setId });
        setSetValidationResult(validationResult);
        setError(undefined);
      }
    } catch (e) {
      if (e instanceof Error) setError(e.message);
    }
  }

  useEffect(() => {
    validate(setId);
  }, [setId]);

  return [setValidationResult, error, () => validate(setId)];
};
