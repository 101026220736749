/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBodyZone
 */
export interface CreateBodyZone {
    /**
     * 
     * @type {string}
     * @memberof CreateBodyZone
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBodyZone
     */
    zoneTagIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBodyZone
     */
    isDefaultZone: boolean;
}

/**
 * Check if a given object implements the CreateBodyZone interface.
 */
export function instanceOfCreateBodyZone(value: object): value is CreateBodyZone {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('zoneTagIds' in value) || value['zoneTagIds'] === undefined) return false;
    if (!('isDefaultZone' in value) || value['isDefaultZone'] === undefined) return false;
    return true;
}

export function CreateBodyZoneFromJSON(json: any): CreateBodyZone {
    return CreateBodyZoneFromJSONTyped(json, false);
}

export function CreateBodyZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBodyZone {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'zoneTagIds': json['zoneTagIds'],
        'isDefaultZone': json['isDefaultZone'],
    };
}

export function CreateBodyZoneToJSON(json: any): CreateBodyZone {
    return CreateBodyZoneToJSONTyped(json, false);
}

export function CreateBodyZoneToJSONTyped(value?: CreateBodyZone | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'zoneTagIds': value['zoneTagIds'],
        'isDefaultZone': value['isDefaultZone'],
    };
}

