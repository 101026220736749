/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserDTO
 */
export interface CreateUserDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    password: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserDTO
     */
    accountType: CreateUserDTOAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    externalIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    identityProvider: CreateUserDTOIdentityProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDTO
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserDTO
     */
    roles: Array<CreateUserDTORolesEnum>;
}


/**
 * @export
 */
export const CreateUserDTOAccountTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type CreateUserDTOAccountTypeEnum = typeof CreateUserDTOAccountTypeEnum[keyof typeof CreateUserDTOAccountTypeEnum];

/**
 * @export
 */
export const CreateUserDTOIdentityProviderEnum = {
    Local: 'local',
    Google: 'google'
} as const;
export type CreateUserDTOIdentityProviderEnum = typeof CreateUserDTOIdentityProviderEnum[keyof typeof CreateUserDTOIdentityProviderEnum];

/**
 * @export
 */
export const CreateUserDTORolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;
export type CreateUserDTORolesEnum = typeof CreateUserDTORolesEnum[keyof typeof CreateUserDTORolesEnum];


/**
 * Check if a given object implements the CreateUserDTO interface.
 */
export function instanceOfCreateUserDTO(value: object): value is CreateUserDTO {
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('accountType' in value) || value['accountType'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('identityProvider' in value) || value['identityProvider'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    return true;
}

export function CreateUserDTOFromJSON(json: any): CreateUserDTO {
    return CreateUserDTOFromJSONTyped(json, false);
}

export function CreateUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'password': json['password'],
        'accountType': json['accountType'],
        'username': json['username'],
        'externalIdentity': json['externalIdentity'] == null ? undefined : json['externalIdentity'],
        'identityProvider': json['identityProvider'],
        'active': json['active'],
        'roles': json['roles'],
    };
}

export function CreateUserDTOToJSON(json: any): CreateUserDTO {
    return CreateUserDTOToJSONTyped(json, false);
}

export function CreateUserDTOToJSONTyped(value?: CreateUserDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'password': value['password'],
        'accountType': value['accountType'],
        'username': value['username'],
        'externalIdentity': value['externalIdentity'],
        'identityProvider': value['identityProvider'],
        'active': value['active'],
        'roles': value['roles'],
    };
}

