/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThemeCSS
 */
export interface ThemeCSS {
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    background: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    tint: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    tabIconDefault: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    tabIconSelected: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    activeTintColor: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    headerColor: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    headerTintColor: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeCSS
     */
    largeInfoTextColor: string;
}

/**
 * Check if a given object implements the ThemeCSS interface.
 */
export function instanceOfThemeCSS(value: object): value is ThemeCSS {
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('background' in value) || value['background'] === undefined) return false;
    if (!('tint' in value) || value['tint'] === undefined) return false;
    if (!('tabIconDefault' in value) || value['tabIconDefault'] === undefined) return false;
    if (!('tabIconSelected' in value) || value['tabIconSelected'] === undefined) return false;
    if (!('activeTintColor' in value) || value['activeTintColor'] === undefined) return false;
    if (!('headerColor' in value) || value['headerColor'] === undefined) return false;
    if (!('headerTintColor' in value) || value['headerTintColor'] === undefined) return false;
    if (!('largeInfoTextColor' in value) || value['largeInfoTextColor'] === undefined) return false;
    return true;
}

export function ThemeCSSFromJSON(json: any): ThemeCSS {
    return ThemeCSSFromJSONTyped(json, false);
}

export function ThemeCSSFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeCSS {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'],
        'background': json['background'],
        'tint': json['tint'],
        'tabIconDefault': json['tabIconDefault'],
        'tabIconSelected': json['tabIconSelected'],
        'activeTintColor': json['activeTintColor'],
        'headerColor': json['headerColor'],
        'headerTintColor': json['headerTintColor'],
        'largeInfoTextColor': json['largeInfoTextColor'],
    };
}

export function ThemeCSSToJSON(json: any): ThemeCSS {
    return ThemeCSSToJSONTyped(json, false);
}

export function ThemeCSSToJSONTyped(value?: ThemeCSS | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'text': value['text'],
        'background': value['background'],
        'tint': value['tint'],
        'tabIconDefault': value['tabIconDefault'],
        'tabIconSelected': value['tabIconSelected'],
        'activeTintColor': value['activeTintColor'],
        'headerColor': value['headerColor'],
        'headerTintColor': value['headerTintColor'],
        'largeInfoTextColor': value['largeInfoTextColor'],
    };
}

