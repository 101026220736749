import { ReactElement, ReactNode } from 'react';
import { GoogleReCaptchaProvider as GRP } from 'react-google-recaptcha-v3';
import { GoogleOAuthProvider as GOP } from '@react-oauth/google';

interface IGoogleReCaptchaProviderProps {
  reCaptchaKey: string;
  language?: string;
  useRecaptchaNet?: boolean;
  useEnterprise?: boolean;
  scriptProps?: {
    nonce?: string;
    defer?: boolean;
    async?: boolean;
    appendTo?: 'head' | 'body';
    id?: string;
    onLoadCallbackName?: string;
  };
  container?: {
    element?: string | HTMLElement;
    parameters: {
      badge?: 'inline' | 'bottomleft' | 'bottomright';
      theme?: 'dark' | 'light';
      tabindex?: number;
      callback?: () => void;
      expiredCallback?: () => void;
      errorCallback?: () => void;
    };
  };
  children: ReactNode;
}

interface UseLoadGsiScriptOptions {
  /**
   * Nonce applied to GSI script tag. Propagates to GSI's inline style tag
   */
  nonce?: string;
  /**
   * Callback fires on load [gsi](https://accounts.google.com/gsi/client) script success
   */
  onScriptLoadSuccess?: () => void;
  /**
   * Callback fires on load [gsi](https://accounts.google.com/gsi/client) script failure
   */
  onScriptLoadError?: () => void;
}

interface GoogleOAuthProviderProps extends UseLoadGsiScriptOptions {
  clientId: string;
  children: ReactNode;
}

export function GoogleReCaptchaProvider(props: IGoogleReCaptchaProviderProps): ReactElement {
  return GRP(props) as any;
}

export function GoogleOAuthProvider(props: GoogleOAuthProviderProps): ReactElement {
  return GOP(props) as any;
}
