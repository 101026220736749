/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Workout
 */
export interface Workout {
    /**
     * 
     * @type {string}
     * @memberof Workout
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Workout
     */
    workoutName: string;
    /**
     * 
     * @type {Array<Array<object>>}
     * @memberof Workout
     */
    workoutRounds: Array<Array<object>>;
}

/**
 * Check if a given object implements the Workout interface.
 */
export function instanceOfWorkout(value: object): value is Workout {
    if (!('workoutName' in value) || value['workoutName'] === undefined) return false;
    if (!('workoutRounds' in value) || value['workoutRounds'] === undefined) return false;
    return true;
}

export function WorkoutFromJSON(json: any): Workout {
    return WorkoutFromJSONTyped(json, false);
}

export function WorkoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workout {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'workoutName': json['workoutName'],
        'workoutRounds': json['workoutRounds'],
    };
}

export function WorkoutToJSON(json: any): Workout {
    return WorkoutToJSONTyped(json, false);
}

export function WorkoutToJSONTyped(value?: Workout | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'workoutName': value['workoutName'],
        'workoutRounds': value['workoutRounds'],
    };
}

