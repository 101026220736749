import { Avatar, Box, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import appImage from '../images/guide/app-image.png';
import dialImage from '../images/guide/training-dial.png';
import workoutManagerImage from '../images/guide/workouts-menu.png';
import timerPrepImage from '../images/guide/timer-prep.png';
import EmojiObjects from '@mui/icons-material/EmojiObjects';
import { AccessAlarm, Build, Cached, ElectricBolt, Https, Timeline } from '@mui/icons-material';

const appName = 'Check for Fitness';

export const UserGuide = () => {
  return (
    <Container>
      <Typography sx={{ mt: 3, textAlign: 'center' }} variant="h3" gutterBottom>
        User Guides
      </Typography>
      <Typography variant="h5" sx={{ textAlign: 'center' }} gutterBottom>
        "{appName}" Guide
      </Typography>
      <Grid sx={{ justifyItems: 'center', alignItems: 'center' }} container>
        <Grid sm={12} md={12} sx={{ justifyItems: 'center' }}>
          <Typography variant="h6">Overview of APP functions</Typography>
        </Grid>
        <Grid container sm={12} md={6} sx={{}}>
          <Grid sx={{ justifyItems: 'right' }} sm={6}>
            <Box sx={{ m: 3, maxHeight: { xs: 200, md: 300 }, display: 'flex' }} component={'img'} src={appImage}></Box>
          </Grid>
          <Grid sx={{ justifyItems: 'left' }} sm={6}>
            <Box sx={{ m: 3, maxHeight: { xs: 200, md: 300 }, display: 'flex' }} component={'img'} src={timerPrepImage}></Box>
          </Grid>
        </Grid>

        <Grid sm={12} md={6}>
          <Box>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <EmojiObjects sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`Create your WORKOUT for your everyday gym visit with "${appName}", without having to remember all your favourite exercises.`} />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <ElectricBolt sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`The APP enables you to quickly generate WORKOUTS that include timed and non-timed exercises.`} />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <Timeline sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`The APP helps you to keep track of how many exercises you have already completed during your current gym visit.`} />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <AccessAlarm sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${appName} includes a timer that you can individually configure on a per exercise basis.`} />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid sm={12} md={12} sx={{ justifyItems: 'center' }}>
          <Typography variant="h6">Creating your first workout</Typography>
        </Grid>
        <Grid container sm={12} md={6} sx={{}}>
          <Grid sx={{ justifyItems: 'right' }} sm={6}>
            <Box sx={{ m: 3, maxHeight: { xs: 200, md: 300 }, display: 'flex' }} component={'img'} src={dialImage}></Box>
          </Grid>
          <Grid sx={{ justifyItems: 'left' }} sm={6}>
            <Box sx={{ m: 3, maxHeight: { xs: 200, md: 300 }, display: 'flex' }} component={'img'} src={workoutManagerImage}></Box>
          </Grid>
        </Grid>
        <Grid sm={12} md={6}>
          <Box>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <Cached sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`GENERATE your Workout: Open the dial menu at the bottom right and tap WORKOUTS. A new screen with the initially configured WORKOUTS opens, tap  
                the name (18 Cards, 28 Cards, ...) of one of the initial workouts to generate a set of WORKOUT cards.`}
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <Build sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`ADAPT your WORKOUT: Tap one of the rows of cards to quickly choose another exercise of this category (
                  Legs,Back, ...) or to completely switch the exercise category and type.`}
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <Https sx={{ color: 'white', fontSize: 20 }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`START your WORKOUT: When you are ready, press the lock Icon at the top right. The APP is then in WORKOUT mode. Tapping a normal card will 
                set it to completed. Tapping a timer card (orange number) will start the exercise's timer.`}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
