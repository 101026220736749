/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetValidationDTO
 */
export interface SetValidationDTO {
    /**
     * 
     * @type {string}
     * @memberof SetValidationDTO
     */
    setId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetValidationDTO
     */
    validationErrors: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SetValidationDTO
     */
    isValid: boolean;
}

/**
 * Check if a given object implements the SetValidationDTO interface.
 */
export function instanceOfSetValidationDTO(value: object): value is SetValidationDTO {
    if (!('setId' in value) || value['setId'] === undefined) return false;
    if (!('validationErrors' in value) || value['validationErrors'] === undefined) return false;
    if (!('isValid' in value) || value['isValid'] === undefined) return false;
    return true;
}

export function SetValidationDTOFromJSON(json: any): SetValidationDTO {
    return SetValidationDTOFromJSONTyped(json, false);
}

export function SetValidationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetValidationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'setId': json['setId'],
        'validationErrors': json['validationErrors'],
        'isValid': json['isValid'],
    };
}

export function SetValidationDTOToJSON(json: any): SetValidationDTO {
    return SetValidationDTOToJSONTyped(json, false);
}

export function SetValidationDTOToJSONTyped(value?: SetValidationDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'setId': value['setId'],
        'validationErrors': value['validationErrors'],
        'isValid': value['isValid'],
    };
}

