/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface AuthGoogleRefreshTokenRequest {
    token: string;
}

export interface AuthLoginWithGoogleCodeRequest {
    code: string;
    clientType: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async authGoogleRefreshTokenRaw(requestParameters: AuthGoogleRefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling authGoogleRefreshToken().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/refresh-google-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async authGoogleRefreshToken(requestParameters: AuthGoogleRefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.authGoogleRefreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async authLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.authLoginRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authLoginWithGoogleCodeRaw(requestParameters: AuthLoginWithGoogleCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling authLoginWithGoogleCode().'
            );
        }

        if (requestParameters['clientType'] == null) {
            throw new runtime.RequiredError(
                'clientType',
                'Required parameter "clientType" was null or undefined when calling authLoginWithGoogleCode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        if (requestParameters['clientType'] != null) {
            queryParameters['clientType'] = requestParameters['clientType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/redeem-google-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async authLoginWithGoogleCode(requestParameters: AuthLoginWithGoogleCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.authLoginWithGoogleCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
