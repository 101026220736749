/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileCreatedDTO
 */
export interface FileCreatedDTO {
    /**
     * 
     * @type {string}
     * @memberof FileCreatedDTO
     */
    fileId: string;
}

/**
 * Check if a given object implements the FileCreatedDTO interface.
 */
export function instanceOfFileCreatedDTO(value: object): value is FileCreatedDTO {
    if (!('fileId' in value) || value['fileId'] === undefined) return false;
    return true;
}

export function FileCreatedDTOFromJSON(json: any): FileCreatedDTO {
    return FileCreatedDTOFromJSONTyped(json, false);
}

export function FileCreatedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileCreatedDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'fileId': json['fileId'],
    };
}

export function FileCreatedDTOToJSON(json: any): FileCreatedDTO {
    return FileCreatedDTOToJSONTyped(json, false);
}

export function FileCreatedDTOToJSONTyped(value?: FileCreatedDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'fileId': value['fileId'],
    };
}

