/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BodyZone
 */
export interface BodyZone {
    /**
     * 
     * @type {string}
     * @memberof BodyZone
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyZone
     */
    zoneTagIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BodyZone
     */
    isDefaultZone: boolean;
    /**
     * 
     * @type {string}
     * @memberof BodyZone
     */
    id: string;
}

/**
 * Check if a given object implements the BodyZone interface.
 */
export function instanceOfBodyZone(value: object): value is BodyZone {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('zoneTagIds' in value) || value['zoneTagIds'] === undefined) return false;
    if (!('isDefaultZone' in value) || value['isDefaultZone'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function BodyZoneFromJSON(json: any): BodyZone {
    return BodyZoneFromJSONTyped(json, false);
}

export function BodyZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyZone {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'zoneTagIds': json['zoneTagIds'],
        'isDefaultZone': json['isDefaultZone'],
        'id': json['id'],
    };
}

export function BodyZoneToJSON(json: any): BodyZone {
    return BodyZoneToJSONTyped(json, false);
}

export function BodyZoneToJSONTyped(value?: BodyZone | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'zoneTagIds': value['zoneTagIds'],
        'isDefaultZone': value['isDefaultZone'],
        'id': value['id'],
    };
}

