/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SoundMap } from './SoundMap';
import {
    SoundMapFromJSON,
    SoundMapFromJSONTyped,
    SoundMapToJSON,
    SoundMapToJSONTyped,
} from './SoundMap';

/**
 * 
 * @export
 * @interface CreateThemeDTO
 */
export interface CreateThemeDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateThemeDTO
     */
    name: string;
    /**
     * 
     * @type {Array<SoundMap>}
     * @memberof CreateThemeDTO
     */
    soundMaps: Array<SoundMap>;
    /**
     * 
     * @type {string}
     * @memberof CreateThemeDTO
     */
    cardImageId: string;
}

/**
 * Check if a given object implements the CreateThemeDTO interface.
 */
export function instanceOfCreateThemeDTO(value: object): value is CreateThemeDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('soundMaps' in value) || value['soundMaps'] === undefined) return false;
    if (!('cardImageId' in value) || value['cardImageId'] === undefined) return false;
    return true;
}

export function CreateThemeDTOFromJSON(json: any): CreateThemeDTO {
    return CreateThemeDTOFromJSONTyped(json, false);
}

export function CreateThemeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateThemeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'soundMaps': ((json['soundMaps'] as Array<any>).map(SoundMapFromJSON)),
        'cardImageId': json['cardImageId'],
    };
}

export function CreateThemeDTOToJSON(json: any): CreateThemeDTO {
    return CreateThemeDTOToJSONTyped(json, false);
}

export function CreateThemeDTOToJSONTyped(value?: CreateThemeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'soundMaps': ((value['soundMaps'] as Array<any>).map(SoundMapToJSON)),
        'cardImageId': value['cardImageId'],
    };
}

