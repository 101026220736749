/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteSetDTO
 */
export interface DeleteSetDTO {
    /**
     * 
     * @type {string}
     * @memberof DeleteSetDTO
     */
    setId: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteSetDTO
     */
    deleteExercises: boolean;
}

/**
 * Check if a given object implements the DeleteSetDTO interface.
 */
export function instanceOfDeleteSetDTO(value: object): value is DeleteSetDTO {
    if (!('setId' in value) || value['setId'] === undefined) return false;
    if (!('deleteExercises' in value) || value['deleteExercises'] === undefined) return false;
    return true;
}

export function DeleteSetDTOFromJSON(json: any): DeleteSetDTO {
    return DeleteSetDTOFromJSONTyped(json, false);
}

export function DeleteSetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteSetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'setId': json['setId'],
        'deleteExercises': json['deleteExercises'],
    };
}

export function DeleteSetDTOToJSON(json: any): DeleteSetDTO {
    return DeleteSetDTOToJSONTyped(json, false);
}

export function DeleteSetDTOToJSONTyped(value?: DeleteSetDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'setId': value['setId'],
        'deleteExercises': value['deleteExercises'],
    };
}

