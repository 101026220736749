import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLocalUser, isAdminUser } from '../utils/user';
import { DeleteModal } from '../components/modals/DeleteSetModal';
import { CreateSetModal } from '../components/modals/CreateSetModal';
import { CreateSetFromOtherModal } from '../components/modals/CreateSetFromOtherModal';
import { Box, Button, CardActions, Checkbox, Container, FormControlLabel, Grid, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { SetCard } from '../components/SetCard';
import { AddSetDrawer } from '../components/AddSetDrawer';
import { Role } from '../models/user';
import { enqueueSnackbar } from 'notistack';
import { setApi, userApi } from '../generated/clients';
import { SetDTO } from '../generated';
import { getLocalSetList } from '../utils/api-helpers';

const SetRow = (props: SetRowProps) => {
  let user = getLocalUser();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { userId } = useParams();
  const isSetCreator = () => props.set.creatorId === user?.id;

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        nameOfObjectToDelete="Set"
        handleDelete={async () => {
          await setApi.setDeleteSet({ deleteSetDTO: { setId: props.set.id || '', deleteExercises: true } });
          enqueueSnackbar(`Set ${props.set.name} deleted!`, { variant: 'success' });
          props.setRefreshSets(true);
          setShowDeleteModal(false);
        }}
      ></DeleteModal>
      <Grid item xs={12} md={4}>
        <SetCard isDefault={props.isDefault} timeout={1000} set={props.set} isSetCreator={isSetCreator()}>
          <CardActions>
            {isSetCreator() || isAdminUser() ? (
              <Button
                onClick={() => {
                  const ownSetCount = props.setList?.filter((s) => s.creatorId === getLocalUser()?.id).length || 0;

                  if (ownSetCount > 1) {
                    setShowDeleteModal(true);
                  } else {
                    enqueueSnackbar('You cannot delete your last set.', { variant: 'error' });
                  }
                }}
                size="small"
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
            {isAdminUser() && !userId && !props.isDefault && (
              <Button
                onClick={async () => {
                  await setApi.setSetDefaultSet({ setDefaultSetDTO: { setId: props.set.id || '' } });
                  props.setRefreshSets(true);
                }}
              >
                Set as default
              </Button>
            )}
            {isAdminUser() && userId && (
              <Button
                onClick={async () => {
                  if (props.set.id && userId) await setApi.setUnassignSetFromUser({ assignSetDTO: { setId: props.set.id, userId: userId } });
                  props.setRefreshSets(true);
                }}
              >
                Remove
              </Button>
            )}
          </CardActions>
        </SetCard>
      </Grid>
    </>
  );
};

const getShowAllSetsFromLocalStorage = () => localStorage.getItem('showAllSets') === 'true';
const setShowAllSetsInLocalStorage = (showAllSets: boolean) => localStorage.setItem('showAllSets', String(showAllSets));

const getFilterUsername = () => localStorage.getItem('filterUsername') ?? 'any';
const setFilterUsernameL = (username: string) => localStorage.setItem('filterUsername', username);

export const Sets = () => {
  const [setList, setSetList] = useState<Array<SetDTO>>();
  const [filteredSets, setFilteredSets] = useState<Array<SetDTO>>([]);
  const [filterUsername, setFilterUsername] = useState<string>(getFilterUsername());
  const [refreshSets, setRefreshSets] = useState<boolean>(false);
  const [showCreateSetModal, setShowCreateSetModal] = useState(false);
  const [showCreateSetFromOtherModal, setShowCreateSetFromOtherModal] = useState(false);
  const [showAssignSetDrawer, setShowAssignSetDrawer] = useState(false);
  const [defaultSetId, setDefaultSetId] = useState<string>();
  const [defaultSet, setDefaultSet] = useState<SetDTO>();
  const [showDefaultSet, setShowDefaultSet] = useState<boolean>(false);
  const [showAllSets, setShowAllSets] = useState<boolean>(getShowAllSetsFromLocalStorage());
  let user = getLocalUser();
  const { userId } = useParams();
  const userSelectionMode = userId !== undefined;
  const setUserNames: Array<string> = [];
  setList?.forEach((s) => {
    if (!setUserNames.includes(s.creatorName)) setUserNames.push(s.creatorName);
  });

  useEffect(() => {
    const getDefaultSetId = async () => {
      const defaultSetId = await setApi.setGetDefaultSetId();
      setDefaultSetId(defaultSetId);
      const defaultSet = await setApi.setGetSetById({ setId: defaultSetId });
      setDefaultSet(defaultSet);
    };

    if (getLocalUser()?.roles.includes(Role.Admin)) {
      getDefaultSetId();
    }
  }, [refreshSets]);

  useEffect(() => {
    async function initialize() {
      if (!setList || refreshSets) {
        let setList;

        if (showAllSets) {
          setList = await setApi.setListAllSets();
        } else if (userId) {
          const user = await userApi.userGetUserById({ id: userId });
          setList = await setApi.setByUser({ getUserSetsDTO: user });
        } else {
          setList = await getLocalSetList(true);
        }
        setSetList(setList);
        setRefreshSets(false);
      }
    }
    initialize();
  }, [setList, refreshSets, showAllSets]);

  useEffect(() => {
    if (setList) {
      let filtered = setList;
      if (!userSelectionMode && !showAllSets && filterUsername !== 'any') filtered = filtered.filter((s) => s.creatorName === filterUsername);
      setFilteredSets(filtered);
    }
  }, [setList, showAllSets, filterUsername]);

  return (
    <>
      <Container>
        {userId && <AddSetDrawer setAssigned={() => setRefreshSets(true)} setShow={setShowAssignSetDrawer} show={showAssignSetDrawer} userId={userId} />}
        {}
        <Paper sx={{ my: 1, zIndex: 1, padding: 1, position: 'sticky', top: { xs: 60, sm: 65 } }}>
          <Grid sx={{ alignContent: 'center', alignItems: 'center' }} container gap={2} direction={'row'}>
            {!userSelectionMode && (
              <Grid item>
                <Button variant="outlined" onClick={() => setShowCreateSetModal(true)}>
                  Create Set
                </Button>
              </Grid>
            )}
            {!userSelectionMode && (
              <Grid item>
                <Button variant="outlined" onClick={() => setShowCreateSetFromOtherModal(true)}>
                  Create From other Set
                </Button>
              </Grid>
            )}
            {userSelectionMode && (
              <Grid item>
                <Button onClick={() => setShowAssignSetDrawer(true)}>Assign Set</Button>
              </Grid>
            )}
            {user?.roles.includes(Role.Admin) && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(v) => {
                        setShowAllSets(!showAllSets);
                        setShowAllSetsInLocalStorage(!showAllSets);
                        setRefreshSets(true);
                      }}
                      checked={showAllSets}
                    />
                  }
                  label="All Sets"
                />
              </Grid>
            )}
            {!userSelectionMode && user?.roles.includes(Role.Admin) && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(v) => {
                        setShowDefaultSet(!showDefaultSet);
                      }}
                      checked={showDefaultSet}
                    />
                  }
                  label="Default Set"
                />
              </Grid>
            )}
            {!userSelectionMode && (
              <Grid item>
                <Stack gap={1} sx={{ alignItems: 'center' }} direction={'row'}>
                  <Typography>Owner</Typography>
                  <Select
                    value={filterUsername.length ? filterUsername : 'any'}
                    onChange={(e) => {
                      setFilterUsername(e.target.value);
                      setFilterUsernameL(e.target.value);
                    }}
                  >
                    <MenuItem selected key={'any'} value={'any'}>
                      Any
                    </MenuItem>
                    {setUserNames?.map((username) => (
                      <MenuItem key={username} value={username}>
                        {username === getLocalUser()?.username ? 'You' : username}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Paper>

        {showDefaultSet && (
          <Box sx={{ m: 2 }}>
            <Typography sx={{ my: 1 }} variant="h6">
              Default Set{' '}
            </Typography>
            {defaultSet && <SetCard set={defaultSet} isSetCreator={false} timeout={1000} />}
          </Box>
        )}
        <Grid container direction={'row'} spacing={2}>
          {filteredSets.map((s: SetDTO, i) => (
            <SetRow key={s.id} isDefault={defaultSetId === s.id} setRefreshSets={setRefreshSets} set={s} setList={filteredSets} />
          ))}
        </Grid>
        <CreateSetFromOtherModal setRefreshSets={setRefreshSets} show={showCreateSetFromOtherModal} setShow={setShowCreateSetFromOtherModal}></CreateSetFromOtherModal>
        <CreateSetModal setRefreshSets={setRefreshSets} showCreateSetModal={showCreateSetModal} setShowCreateSetModal={setShowCreateSetModal}></CreateSetModal>
      </Container>
    </>
  );
};

interface SetRowProps {
  setList: Array<SetDTO>;
  isDefault: boolean;
  set: SetDTO;
  setRefreshSets: (refresh: boolean) => void;
}
