import { Chip, SxProps, Typography } from '@mui/material';
import { MuscleTag } from '../generated';

interface MuscleTagIdListProps {
  muscleTagIds: Array<string>;
  muscleTagMap?: Map<string, MuscleTag>;
  elementClicked?: (bzt: string) => void;
  sx?: SxProps;
}

export const MuscleTagIdList = (props: MuscleTagIdListProps) => {
  {
    return (
      <>
        {props.muscleTagIds
          ?.filter((id) => props.muscleTagMap?.get(id)?.name)
          .map((bztId) => (
            <h4 style={{ display: 'inline-block', margin: 3 }}>
              <Chip
                label={<Typography>{props.muscleTagMap?.get(bztId)?.name}</Typography>}
                onClick={() => {
                  if (props.elementClicked) props.elementClicked(bztId);
                }}
                sx={props.sx}
              ></Chip>
            </h4>
          ))}
      </>
    );
  }
};
