/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MuscleIntensity
 */
export interface MuscleIntensity {
    /**
     * 
     * @type {string}
     * @memberof MuscleIntensity
     */
    muscleId: string;
    /**
     * 
     * @type {number}
     * @memberof MuscleIntensity
     */
    intensity: MuscleIntensityIntensityEnum;
}


/**
 * @export
 */
export const MuscleIntensityIntensityEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;
export type MuscleIntensityIntensityEnum = typeof MuscleIntensityIntensityEnum[keyof typeof MuscleIntensityIntensityEnum];


/**
 * Check if a given object implements the MuscleIntensity interface.
 */
export function instanceOfMuscleIntensity(value: object): value is MuscleIntensity {
    if (!('muscleId' in value) || value['muscleId'] === undefined) return false;
    if (!('intensity' in value) || value['intensity'] === undefined) return false;
    return true;
}

export function MuscleIntensityFromJSON(json: any): MuscleIntensity {
    return MuscleIntensityFromJSONTyped(json, false);
}

export function MuscleIntensityFromJSONTyped(json: any, ignoreDiscriminator: boolean): MuscleIntensity {
    if (json == null) {
        return json;
    }
    return {
        
        'muscleId': json['muscleId'],
        'intensity': json['intensity'],
    };
}

export function MuscleIntensityToJSON(json: any): MuscleIntensity {
    return MuscleIntensityToJSONTyped(json, false);
}

export function MuscleIntensityToJSONTyped(value?: MuscleIntensity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'muscleId': value['muscleId'],
        'intensity': value['intensity'],
    };
}

