import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useOutsideClick } from '../hooks/click-outside-hook';
import { Box, Chip, Stack, TextField, Typography } from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import { MuscleTag } from '../generated';

interface MuscleTagEditorProps {
  tagEditMode: boolean;
  muscleTags: Array<MuscleTag>;
  tagCreated: (tagName: string) => void;
  tagUpdated: (tag: MuscleTag) => void;
  tagRemoved: (muscleTag: MuscleTag) => void;
  tagClicked?: (muscleTag: MuscleTag) => void;
}

export const MuscleTagEditor = (props: MuscleTagEditorProps) => {
  return (
    <>
      {props.muscleTags.map((bzt) =>
        props.tagEditMode ? (
          <EditableTag key={bzt.id} tag={bzt} tagRemoved={props.tagRemoved} tagUpdated={props.tagUpdated} />
        ) : (
          <ClickableTag key={bzt.id} tag={bzt} tagClicked={props.tagClicked} />
        ),
      )}
      {props.tagEditMode ? <TagAdder tagCreated={(name) => props.tagCreated(name)} /> : undefined}
    </>
  );
};

interface EditableTagProps {
  tag: MuscleTag;
  tagUpdated: (tag: MuscleTag) => void;
  tagRemoved: (tag: MuscleTag) => void;
}

interface ClickableTagProps {
  tag: MuscleTag;
  tagClicked?: (muscleTag: MuscleTag) => void;
}

const ClickableTag = (props: ClickableTagProps) => {
  return (
    <Chip
      onClick={() => {
        if (props.tagClicked) props.tagClicked(props.tag);
      }}
      sx={{ m: 0.2 }}
      label={<Typography style={{ padding: 5, overflow: 'hidden' }}>{props.tag.name}</Typography>}
      style={{ backgroundColor: '#606C38' }}
    ></Chip>
  );
};

const EditableTag = (props: EditableTagProps) => {
  const [editMode, setEditMode] = useState<boolean | undefined>(undefined);
  const [tagName, setTagName] = useState<string>(props.tag.name);
  const elementRef = useRef<HTMLHeadingElement | null>(null);

  useOutsideClick(elementRef.current, () => setEditMode(false));

  useEffect(() => {
    if (editMode === false) {
      props.tagUpdated({ ...props.tag, name: tagName });
    }
  }, [editMode]);

  return (
    <Chip
      sx={{ m: 0.2 }}
      onClick={() => {
        setEditMode(true);
      }}
      label={
        editMode ? (
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Clear
              onClick={() => {
                props.tagRemoved(props.tag);
              }}
            />
            <TextField autoFocus={true} style={{ width: 100 }} variant="standard" value={tagName} onChange={(e) => setTagName(e.target.value)} />
          </Stack>
        ) : (
          <Typography style={{ padding: 5, overflow: 'hidden' }}>{props.tag.name}</Typography>
        )
      }
      style={{ backgroundColor: '#606C38' }}
    ></Chip>
  );
};

interface TagAdderProps {
  tagCreated: (tagName: string) => void;
}

const TagAdder = (props: TagAdderProps) => {
  const [editMode, setEditMode] = useState<boolean | undefined>(undefined);
  const [tagName, setTagName] = useState<string>('');
  const elementRef = useRef<HTMLHeadingElement | null>(null);
  useOutsideClick(elementRef.current, () => setEditMode(false));

  const createTag = (e: React.UIEvent) => {
    props.tagCreated(tagName);
    setTagName('');
    setEditMode(false);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <h4
      ref={elementRef}
      style={{ display: 'inline-block', margin: 3 }}
      onClick={() => {
        setEditMode(true);
      }}
    >
      {!editMode ? (
        <Chip
          label={
            <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
              <Add />
            </Box>
          }
          style={{ backgroundColor: '#606C38' }}
          onClick={() => setEditMode(true)}
        ></Chip>
      ) : (
        <Chip
          label={
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <TextField
                autoFocus={true}
                value={tagName}
                variant="standard"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') createTag(e);
                }}
                onChange={(e) => setTagName(e.target.value)}
              />
              <Add onClick={(e) => createTag(e)} />
            </Stack>
          }
          style={{ backgroundColor: '#606C38' }}
        ></Chip>
      )}
    </h4>
  );
};
