import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Typography } from '@mui/material';
import { getLocalUser } from '../utils/user';
import { useState } from 'react';
import { logout } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { userApi } from '../generated/clients';

export const MyAccount = () => {
  const user = getLocalUser();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleAccountDelete = async () => {
    await userApi.userDeleteUserAccount();
    await logout();
    handleDeleteDialogClose();
    navigate('/login');
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Container>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Really delete your account?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your data including your sets and your exercises will be completely deleted from the server. Are you sure that you want to do this? This process is not reversible!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Disagree</Button>
          <Button onClick={handleAccountDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ m: 2 }} elevation={3}>
        <Box sx={{ m: 2, p: 1 }}>
          <Typography variant="h5">Account Details</Typography>
          <Box sx={{ m: 2 }}>
            <Typography>Username: {user?.username}</Typography>
            <Typography>Roles: {user?.roles.join(', ')}</Typography>
          </Box>

          <Typography variant="h5">Account Settings</Typography>
          <Button onClick={handleDeleteDialogOpen} sx={{ m: 1 }}>
            Delete my Account
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};
