/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ExportExportRequest {
    setId: string;
    themeId: string;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     */
    async exportExportRaw(requestParameters: ExportExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['setId'] == null) {
            throw new runtime.RequiredError(
                'setId',
                'Required parameter "setId" was null or undefined when calling exportExport().'
            );
        }

        if (requestParameters['themeId'] == null) {
            throw new runtime.RequiredError(
                'themeId',
                'Required parameter "themeId" was null or undefined when calling exportExport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['setId'] != null) {
            queryParameters['setId'] = requestParameters['setId'];
        }

        if (requestParameters['themeId'] != null) {
            queryParameters['themeId'] = requestParameters['themeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async exportExport(requestParameters: ExportExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.exportExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
