/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Workout,
} from '../models/index';
import {
    WorkoutFromJSON,
    WorkoutToJSON,
} from '../models/index';

export interface WorkoutCreateOrUpdateRequest {
    workout: Workout;
}

export interface WorkoutDeleteRequest {
    id: string;
}

/**
 * 
 */
export class WorkoutApi extends runtime.BaseAPI {

    /**
     */
    async workoutCreateOrUpdateRaw(requestParameters: WorkoutCreateOrUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workout>> {
        if (requestParameters['workout'] == null) {
            throw new runtime.RequiredError(
                'workout',
                'Required parameter "workout" was null or undefined when calling workoutCreateOrUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/workout`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkoutToJSON(requestParameters['workout']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkoutFromJSON(jsonValue));
    }

    /**
     */
    async workoutCreateOrUpdate(requestParameters: WorkoutCreateOrUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workout> {
        const response = await this.workoutCreateOrUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workoutDeleteRaw(requestParameters: WorkoutDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling workoutDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async workoutDelete(requestParameters: WorkoutDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.workoutDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async workoutListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Workout>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkoutFromJSON));
    }

    /**
     */
    async workoutList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Workout>> {
        const response = await this.workoutListRaw(initOverrides);
        return await response.value();
    }

}
