/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SoundMap } from './SoundMap';
import {
    SoundMapFromJSON,
    SoundMapFromJSONTyped,
    SoundMapToJSON,
    SoundMapToJSONTyped,
} from './SoundMap';
import type { ThemeCSS } from './ThemeCSS';
import {
    ThemeCSSFromJSON,
    ThemeCSSFromJSONTyped,
    ThemeCSSToJSON,
    ThemeCSSToJSONTyped,
} from './ThemeCSS';

/**
 * 
 * @export
 * @interface ThemeDTO
 */
export interface ThemeDTO {
    /**
     * 
     * @type {string}
     * @memberof ThemeDTO
     */
    id: string;
    /**
     * 
     * @type {Array<SoundMap>}
     * @memberof ThemeDTO
     */
    soundMap: Array<SoundMap>;
    /**
     * 
     * @type {string}
     * @memberof ThemeDTO
     */
    cardImageId: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeDTO
     */
    name: string;
    /**
     * 
     * @type {ThemeCSS}
     * @memberof ThemeDTO
     */
    css: ThemeCSS;
}

/**
 * Check if a given object implements the ThemeDTO interface.
 */
export function instanceOfThemeDTO(value: object): value is ThemeDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('soundMap' in value) || value['soundMap'] === undefined) return false;
    if (!('cardImageId' in value) || value['cardImageId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('css' in value) || value['css'] === undefined) return false;
    return true;
}

export function ThemeDTOFromJSON(json: any): ThemeDTO {
    return ThemeDTOFromJSONTyped(json, false);
}

export function ThemeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'soundMap': ((json['soundMap'] as Array<any>).map(SoundMapFromJSON)),
        'cardImageId': json['cardImageId'],
        'name': json['name'],
        'css': ThemeCSSFromJSON(json['css']),
    };
}

export function ThemeDTOToJSON(json: any): ThemeDTO {
    return ThemeDTOToJSONTyped(json, false);
}

export function ThemeDTOToJSONTyped(value?: ThemeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'soundMap': ((value['soundMap'] as Array<any>).map(SoundMapToJSON)),
        'cardImageId': value['cardImageId'],
        'name': value['name'],
        'css': ThemeCSSToJSON(value['css']),
    };
}

