import { useEffect, useState } from 'react';
import { getLocalUser } from '../utils/user';
import { CreateUserModal } from '../components/modals/CreateUserModal';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  Container,
  CardActions,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { UserCard } from '../components/UserCard';
import { EditUserModal } from '../components/modals/EditUserModal';
import { userApi } from '../generated/clients';
import { UserDTO } from '../generated';

type DeleteUserDialogProps = { open: boolean; userId?: string; setOpen: (open: boolean) => void };

const DeleteUserDialog = (props: DeleteUserDialogProps) => {
  const { open, setOpen } = props;

  const handleClose = async (del: boolean) => {
    if (del && props.userId) await userApi.userDeleteUser({ id: props.userId });
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete this user?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Really delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} autoFocus>
            Delete
          </Button>
          <Button onClick={() => handleClose(false)}>Abort</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getSortBy = () => localStorage.getItem('sortBy');
const setSortBy = (sortBy: string) => localStorage.setItem('sortBy', sortBy);

export const Users = () => {
  const [userList, setUserList] = useState<Array<UserDTO>>();
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false);
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<UserDTO>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string>();
  const [userSortFilter, setUserSortFilter] = useState<string | null | undefined>(getSortBy() ?? undefined);
  const [userSearchText, setUserSearchText] = useState<string>('');

  const user = getLocalUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userList || showCreateUserModal === false) {
      const getUsers = async () => {
        const users = await userApi.userListUsers();
        setUserList(users);
      };
      getUsers();
    }
  }, [showCreateUserModal, showEditUserModal, deleteDialogOpen]);

  let filteredUsers = userList;

  if (userSortFilter === 'lastLogin')
    filteredUsers = userList?.sort((a, b) => {
      const aTime = a.lastLogin ? new Date(a.lastLogin).getTime() : 0;
      const bTime = b.lastLogin ? new Date(b.lastLogin).getTime() : 0;

      return aTime > bTime ? -1 : 1;
    });
  else if (userSortFilter === 'name') filteredUsers = userList?.sort((a, b) => (a.username > b.username ? 1 : -1));
  else if (userSortFilter === 'none') filteredUsers = userList;
  if (userSearchText.trim().length) filteredUsers = filteredUsers?.filter((fu) => fu.username.includes(userSearchText.trim()));

  return (
    <>
      <Container>
        Sort by
        <Select
          defaultValue={'none'}
          sx={{ m: 2 }}
          value={userSortFilter}
          onChange={(e) => {
            setUserSortFilter(e.target.value);
            if (e.target.value) setSortBy(e.target.value);
          }}
        >
          <MenuItem value={'none'}>Nothing</MenuItem>
          <MenuItem value={'lastLogin'}>Last Login</MenuItem>
          <MenuItem value={'name'}>User Name</MenuItem>
        </Select>
        <TextField value={userSearchText} onChange={(e) => setUserSearchText(e.target.value)} sx={{ m: 2 }} id="outlined-basic" label="Search" variant="outlined" />
        <DeleteUserDialog userId={userIdToDelete} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />
        <Box component="section" sx={{ my: 2 }}>
          <Button
            onClick={() => {
              setShowCreateUserModal(true);
            }}
          >
            Create User
          </Button>
        </Box>
        <Grid container direction={'row'} spacing={2}>
          {filteredUsers?.map((u) => (
            <Grid item xs={12} md={4}>
              <UserCard user={u} timeout={1000}>
                <CardActions>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={u?.active}
                        onChange={(e) => {
                          if (user) {
                            //TODO: Update
                            // setUser({ ...user, active: !u.active });
                          }
                        }}
                      />
                    }
                    label="Active"
                  />
                  <Button
                    onClick={() => {
                      setEditUser(u);
                      setShowEditUserModal(true);
                    }}
                    style={{}}
                  >
                    Edit User
                  </Button>
                  <Button
                    onClick={async () => {
                      setUserIdToDelete(u.userId);
                      setDeleteDialogOpen(true);
                    }}
                    style={{}}
                  >
                    Delete User
                  </Button>
                </CardActions>
              </UserCard>
            </Grid>
          ))}
        </Grid>
        <CreateUserModal showCreateUserModal={showCreateUserModal} setShowCreateUserModal={setShowCreateUserModal} />
        {editUser && (
          <EditUserModal
            user={editUser}
            show={showEditUserModal}
            setShow={(v) => {
              setShowEditUserModal(v);
              if (v === false) setEditUser(undefined);
            }}
          />
        )}
      </Container>
    </>
  );
};
