/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyExerciseDTO
 */
export interface CopyExerciseDTO {
    /**
     * 
     * @type {string}
     * @memberof CopyExerciseDTO
     */
    exerciseId: string;
    /**
     * 
     * @type {string}
     * @memberof CopyExerciseDTO
     */
    targetSetId: string;
}

/**
 * Check if a given object implements the CopyExerciseDTO interface.
 */
export function instanceOfCopyExerciseDTO(value: object): value is CopyExerciseDTO {
    if (!('exerciseId' in value) || value['exerciseId'] === undefined) return false;
    if (!('targetSetId' in value) || value['targetSetId'] === undefined) return false;
    return true;
}

export function CopyExerciseDTOFromJSON(json: any): CopyExerciseDTO {
    return CopyExerciseDTOFromJSONTyped(json, false);
}

export function CopyExerciseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyExerciseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'exerciseId': json['exerciseId'],
        'targetSetId': json['targetSetId'],
    };
}

export function CopyExerciseDTOToJSON(json: any): CopyExerciseDTO {
    return CopyExerciseDTOToJSONTyped(json, false);
}

export function CopyExerciseDTOToJSONTyped(value?: CopyExerciseDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exerciseId': value['exerciseId'],
        'targetSetId': value['targetSetId'],
    };
}

