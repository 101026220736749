/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OCRResultDTO,
} from '../models/index';
import {
    OCRResultDTOFromJSON,
    OCRResultDTOToJSON,
} from '../models/index';

/**
 * 
 */
export class OCRApi extends runtime.BaseAPI {

    /**
     */
    async oCRExtractTextRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OCRResultDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ocr/extractText`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OCRResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async oCRExtractText(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OCRResultDTO> {
        const response = await this.oCRExtractTextRaw(initOverrides);
        return await response.value();
    }

}
