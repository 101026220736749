/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileDTO
 */
export interface UserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    username: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfileDTO
     */
    roles: Array<UserProfileDTORolesEnum>;
}


/**
 * @export
 */
export const UserProfileDTORolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;
export type UserProfileDTORolesEnum = typeof UserProfileDTORolesEnum[keyof typeof UserProfileDTORolesEnum];


/**
 * Check if a given object implements the UserProfileDTO interface.
 */
export function instanceOfUserProfileDTO(value: object): value is UserProfileDTO {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    return true;
}

export function UserProfileDTOFromJSON(json: any): UserProfileDTO {
    return UserProfileDTOFromJSONTyped(json, false);
}

export function UserProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'roles': json['roles'],
    };
}

export function UserProfileDTOToJSON(json: any): UserProfileDTO {
    return UserProfileDTOToJSONTyped(json, false);
}

export function UserProfileDTOToJSONTyped(value?: UserProfileDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'username': value['username'],
        'roles': value['roles'],
    };
}

