import { useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { enqueueSnackbar } from 'notistack';
import { setApi } from '../../generated/clients';

export interface CreateSetModalProps {
  showCreateSetModal: boolean;
  setShowCreateSetModal: (s: boolean) => void;
  setRefreshSets: (s: boolean) => void;
}

export const CreateSetModal = (props: CreateSetModalProps) => {
  const [setDescription, setSetDescription] = useState<string>();
  const [setName, setSetName] = useState<string>();
  return (
    <Modal open={props.showCreateSetModal} onClose={() => props.setShowCreateSetModal(false)}>
      <Box sx={modalStyle}>
        <Typography variant="h4">Create New Set</Typography>
        <TextField style={{ marginTop: 30 }} onChange={(e) => setSetName(e.target.value)} label="Name" />

        <Box>
          <TextField label={'Set Description'} onChange={(v) => setSetDescription(v.target.value)} style={{ width: 250, marginTop: 10 }} multiline rows={4} />
        </Box>
        <Button
          style={{ marginTop: 10 }}
          onClick={async () => {
            props.setShowCreateSetModal(false);
            try {
              await setApi.setCreateSet({ createSetDTO: { name: setName || '', description: setDescription } });
              enqueueSnackbar(`Set ${setName} created!`, { variant: 'success' });
              props.setRefreshSets(true);
            } catch (e) {
              if (e instanceof Error) {
                enqueueSnackbar(JSON.parse(e.message).message, { variant: 'error' });
              }
            }
          }}
        >
          Create Set
        </Button>
        <Button style={{ marginTop: 10 }} onClick={() => props.setShowCreateSetModal(false)}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
