import { LocalUser, Role } from '../models/user';
import { logout } from './auth';

export const setLocalUser = (user: LocalUser) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const deleteLocalUser = () => {
  localStorage.removeItem('user');
};

export const getLocalUser = (): LocalUser | null => {
  const localUserstring = localStorage.getItem('user');
  if (localUserstring) return JSON.parse(localUserstring);
  return null;
  //throw new Error('User not set.');
};

export const isLoggedIn = () => {
  return getLocalUser() !== null;
};

export const isAdminUser = () => {
  //Try/catch, fix legacy login mobile issue
  try {
    return getLocalUser()?.roles.includes(Role.Admin);
  } catch (e) {
    logout();
    return false;
  }
};
