/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserSetsDTO
 */
export interface GetUserSetsDTO {
    /**
     * 
     * @type {string}
     * @memberof GetUserSetsDTO
     */
    userId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserSetsDTO
     */
    setIds: Array<string>;
}

/**
 * Check if a given object implements the GetUserSetsDTO interface.
 */
export function instanceOfGetUserSetsDTO(value: object): value is GetUserSetsDTO {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('setIds' in value) || value['setIds'] === undefined) return false;
    return true;
}

export function GetUserSetsDTOFromJSON(json: any): GetUserSetsDTO {
    return GetUserSetsDTOFromJSONTyped(json, false);
}

export function GetUserSetsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserSetsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'setIds': json['setIds'],
    };
}

export function GetUserSetsDTOToJSON(json: any): GetUserSetsDTO {
    return GetUserSetsDTOToJSONTyped(json, false);
}

export function GetUserSetsDTOToJSONTyped(value?: GetUserSetsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userId': value['userId'],
        'setIds': value['setIds'],
    };
}

