import { useEffect, useState } from 'react';
import { exerciseApi } from '../generated/clients';
import { ExerciseDTO } from '../generated';

export const useExercises = (setId?: string): [Array<ExerciseDTO> | undefined, string | undefined, () => void] => {
  const [exerciseList, setExerciseList] = useState<Array<ExerciseDTO>>();
  const [error, setError] = useState<string>();

  async function refreshExercises() {
    try {
      if (setId) {
        const l = await exerciseApi.exerciseList({ setId });
        setExerciseList(l);
        setError(undefined);
      }
    } catch (e) {
      if (e instanceof Error) setError(e.message);
    }
  }

  useEffect(() => {
    refreshExercises();
  }, [setId]);

  return [exerciseList, error, refreshExercises];
};
