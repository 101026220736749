import { useState } from 'react';
import { AccountType, Role, UpdateUserDTO, User } from '../../models/user';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { userApi } from '../../generated/clients';
import { UserDTO } from '../../generated';

export interface EditUserModalProps {
  user: UserDTO;
  show: boolean;
  setShow: (s: boolean) => void;
}

export const EditUserModal = (props: EditUserModalProps) => {
  const { user } = props;
  const [userName, setUserName] = useState<string>(user.username);
  const [accountType, setAccountType] = useState<AccountType>(user.accountType);
  const [isAdmin, setIsAdmin] = useState<boolean>(user.roles.includes(Role.Admin));
  const [isUser, setIsUser] = useState<boolean>(user.roles.includes(Role.User));
  const [isActive, setIsActive] = useState<boolean>(user.active);

  return (
    <Modal open={props.show} onClose={() => props.setShow(false)}>
      <Box sx={modalStyle}>
        <Typography sx={{ margin: 2 }} variant="h5">
          Edit User
        </Typography>
        <TextField value={userName} onChange={(e) => setUserName(e.target.value)} label="Name" />
        &nbsp;
        <div key={`roles`} className="mb-3">
          <FormControlLabel control={<Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />} label="Admin" />
          <FormControlLabel control={<Checkbox checked={isUser} onChange={(e) => setIsUser(e.target.checked)} />} label="User" />
        </div>
        <FormControlLabel control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />} label="Active" />
        <Box>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Account Type</FormLabel>
            <RadioGroup
              row
              value={accountType}
              onChange={(e) => {
                console.log(e.target.value as unknown as AccountType);
                setAccountType(e.target.value as unknown as AccountType);
              }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={user.accountType}
              name="radio-buttons-group"
            >
              <FormControlLabel value={AccountType.basic} control={<Radio />} label="Basic" />
              <FormControlLabel value={AccountType.premium} control={<Radio />} label="Premium" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Button
            onClick={async () => {
              const roles: Array<Role> = [];
              if (isAdmin) roles.push(Role.Admin);
              if (isUser) roles.push(Role.User);
              const updateUserObj: UpdateUserDTO = { accountType, active: isActive, roles, username: userName, userId: user.userId };
              await userApi.userUpdateUser({ updateUserDTO: updateUserObj });
              props.setShow(false);
            }}
          >
            Update User
          </Button>
          <Button onClick={() => props.setShow(false)}>Close</Button>
        </Box>
      </Box>
    </Modal>
  );
};
