/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SoundMap
 */
export interface SoundMap {
    /**
     * 
     * @type {number}
     * @memberof SoundMap
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SoundMap
     */
    soundId: string;
}

/**
 * Check if a given object implements the SoundMap interface.
 */
export function instanceOfSoundMap(value: object): value is SoundMap {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('soundId' in value) || value['soundId'] === undefined) return false;
    return true;
}

export function SoundMapFromJSON(json: any): SoundMap {
    return SoundMapFromJSONTyped(json, false);
}

export function SoundMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoundMap {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'soundId': json['soundId'],
    };
}

export function SoundMapToJSON(json: any): SoundMap {
    return SoundMapToJSONTyped(json, false);
}

export function SoundMapToJSONTyped(value?: SoundMap | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'soundId': value['soundId'],
    };
}

