import { useEffect, useState } from 'react';
import { bodyZoneApi } from '../generated/clients';
import { BodyZone } from '../generated';

export function useBodyZoneList(): [Array<BodyZone>, string | undefined, () => void] {
  const [bodyZones, setBodyZones] = useState<Array<BodyZone>>([]);
  const [error, setError] = useState<string>();
  const loadBodyZones = async () => {
    try {
      const bz = await bodyZoneApi.bodyZoneList();
      setBodyZones(bz);
      setError(undefined);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    loadBodyZones();
  }, []);

  return [bodyZones, error, loadBodyZones];
}
