import { OCRResultDTO, SetDTO } from '../generated';
import { fileApi, ocrApi, setApi } from '../generated/clients';

export const mapFileToRequestBody = (file: Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  return { body: formData };
};

export const uploadFile = async (file: File): Promise<string> => {
  const response = await (await fileApi.fileUploadFileRaw(mapFileToRequestBody(file))).raw.json();
  const fileId = response.fileId;
  return fileId;
};

export const serverOcr = async (file: Blob): Promise<OCRResultDTO> => {
  const response = (await ocrApi.oCRExtractTextRaw(mapFileToRequestBody(file))).raw;
  return await response.json();
};

export const getLocalSetList = async (forceUpdate: boolean = false): Promise<Array<SetDTO>> => {
  const localSetList = localStorage.getItem('setList');
  if (!localSetList || forceUpdate) {
    //TODO: Reauth
    const setList = await setApi.setSets({ onlyEditable: '0' });
    if (setList) {
      localStorage.setItem('setList', JSON.stringify(setList));
      return setList;
    }
    throw new Error('Could not get set List');
  }
  return JSON.parse(localSetList);
};
