import { useEffect, useState } from 'react';
import { SetDTO } from '../generated';
import { getLocalSetList } from '../utils/api-helpers';

export const useSet = (setId?: string): [set: SetDTO | undefined, refreshSet: (force?: boolean) => void] => {
  const [set, setSet] = useState<SetDTO>();

  async function initialize(force?: boolean) {
    const localSetList = await getLocalSetList(force);
    const set = localSetList?.find((s) => s.id === setId);
    setSet(set);
  }
  useEffect(() => {
    initialize();
  }, [setId]);

  return [set, initialize];
};
