/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopySetDTO
 */
export interface CopySetDTO {
    /**
     * 
     * @type {string}
     * @memberof CopySetDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CopySetDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CopySetDTO
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof CopySetDTO
     */
    sourceSetId: string;
}

/**
 * Check if a given object implements the CopySetDTO interface.
 */
export function instanceOfCopySetDTO(value: object): value is CopySetDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('sourceSetId' in value) || value['sourceSetId'] === undefined) return false;
    return true;
}

export function CopySetDTOFromJSON(json: any): CopySetDTO {
    return CopySetDTOFromJSONTyped(json, false);
}

export function CopySetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopySetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'imageId': json['imageId'] == null ? undefined : json['imageId'],
        'sourceSetId': json['sourceSetId'],
    };
}

export function CopySetDTOToJSON(json: any): CopySetDTO {
    return CopySetDTOToJSONTyped(json, false);
}

export function CopySetDTOToJSONTyped(value?: CopySetDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'description': value['description'],
        'imageId': value['imageId'],
        'sourceSetId': value['sourceSetId'],
    };
}

