/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateThemeDTO,
  ThemeDTO,
} from '../models/index';
import {
    CreateThemeDTOFromJSON,
    CreateThemeDTOToJSON,
    ThemeDTOFromJSON,
    ThemeDTOToJSON,
} from '../models/index';

export interface ThemeCreateRequest {
    createThemeDTO: CreateThemeDTO;
}

export interface ThemeGetByIdRequest {
    id: string;
}

export interface ThemeGetByNameRequest {
    name: string;
}

export interface ThemeUpdateRequest {
    themeDTO: ThemeDTO;
}

/**
 * 
 */
export class ThemeApi extends runtime.BaseAPI {

    /**
     */
    async themeCreateRaw(requestParameters: ThemeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['createThemeDTO'] == null) {
            throw new runtime.RequiredError(
                'createThemeDTO',
                'Required parameter "createThemeDTO" was null or undefined when calling themeCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/theme/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateThemeDTOToJSON(requestParameters['createThemeDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async themeCreate(requestParameters: ThemeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.themeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async themeGetByIdRaw(requestParameters: ThemeGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling themeGetById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/theme/getById{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async themeGetById(requestParameters: ThemeGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.themeGetByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async themeGetByNameRaw(requestParameters: ThemeGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThemeDTO>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling themeGetByName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/theme/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThemeDTOFromJSON(jsonValue));
    }

    /**
     */
    async themeGetByName(requestParameters: ThemeGetByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThemeDTO> {
        const response = await this.themeGetByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async themeListNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/theme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async themeListNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.themeListNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async themeListThemesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ThemeDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/theme/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThemeDTOFromJSON));
    }

    /**
     */
    async themeListThemes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ThemeDTO>> {
        const response = await this.themeListThemesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async themeUpdateRaw(requestParameters: ThemeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThemeDTO>> {
        if (requestParameters['themeDTO'] == null) {
            throw new runtime.RequiredError(
                'themeDTO',
                'Required parameter "themeDTO" was null or undefined when calling themeUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/theme/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ThemeDTOToJSON(requestParameters['themeDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThemeDTOFromJSON(jsonValue));
    }

    /**
     */
    async themeUpdate(requestParameters: ThemeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThemeDTO> {
        const response = await this.themeUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
