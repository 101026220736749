/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateMuscleTag,
  MuscleTag,
} from '../models/index';
import {
    CreateMuscleTagFromJSON,
    CreateMuscleTagToJSON,
    MuscleTagFromJSON,
    MuscleTagToJSON,
} from '../models/index';

export interface MuscleTagCreateRequest {
    createMuscleTag: CreateMuscleTag;
}

export interface MuscleTagDeleteRequest {
    tagId: string;
}

export interface MuscleTagUpdateRequest {
    muscleTag: MuscleTag;
}

/**
 * 
 */
export class MuscleTagApi extends runtime.BaseAPI {

    /**
     */
    async muscleTagCreateRaw(requestParameters: MuscleTagCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MuscleTag>> {
        if (requestParameters['createMuscleTag'] == null) {
            throw new runtime.RequiredError(
                'createMuscleTag',
                'Required parameter "createMuscleTag" was null or undefined when calling muscleTagCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/muscle-tag/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMuscleTagToJSON(requestParameters['createMuscleTag']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MuscleTagFromJSON(jsonValue));
    }

    /**
     */
    async muscleTagCreate(requestParameters: MuscleTagCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MuscleTag> {
        const response = await this.muscleTagCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async muscleTagDeleteRaw(requestParameters: MuscleTagDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['tagId'] == null) {
            throw new runtime.RequiredError(
                'tagId',
                'Required parameter "tagId" was null or undefined when calling muscleTagDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/muscle-tag/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters['tagId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async muscleTagDelete(requestParameters: MuscleTagDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.muscleTagDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async muscleTagListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MuscleTag>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/muscle-tag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MuscleTagFromJSON));
    }

    /**
     */
    async muscleTagList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MuscleTag>> {
        const response = await this.muscleTagListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async muscleTagUpdateRaw(requestParameters: MuscleTagUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MuscleTag>> {
        if (requestParameters['muscleTag'] == null) {
            throw new runtime.RequiredError(
                'muscleTag',
                'Required parameter "muscleTag" was null or undefined when calling muscleTagUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/muscle-tag/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MuscleTagToJSON(requestParameters['muscleTag']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MuscleTagFromJSON(jsonValue));
    }

    /**
     */
    async muscleTagUpdate(requestParameters: MuscleTagUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MuscleTag> {
        const response = await this.muscleTagUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
