/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileCreatedDTO,
} from '../models/index';
import {
    FileCreatedDTOFromJSON,
    FileCreatedDTOToJSON,
} from '../models/index';

export interface FileGetFileByIdRequest {
    id: string;
}

export interface FileGetFileHeaderRequest {
    id: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     */
    async fileGetFileByIdRaw(requestParameters: FileGetFileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fileGetFileById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fileGetFileById(requestParameters: FileGetFileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fileGetFileByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fileGetFileHeaderRaw(requestParameters: FileGetFileHeaderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fileGetFileHeader().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fileGetFileHeader(requestParameters: FileGetFileHeaderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fileGetFileHeaderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fileUploadFileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileCreatedDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/upload-file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileCreatedDTOFromJSON(jsonValue));
    }

    /**
     */
    async fileUploadFile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileCreatedDTO> {
        const response = await this.fileUploadFileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fileUploadFileOldRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fileUploadFileOld(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.fileUploadFileOldRaw(initOverrides);
        return await response.value();
    }

}
