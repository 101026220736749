/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignSetDTO,
  CopySetDTO,
  CreateSetDTO,
  DeleteSetDTO,
  GetUserSetsDTO,
  SetDTO,
  SetDefaultSetDTO,
  SetValidationDTO,
  UserDTO,
} from '../models/index';
import {
    AssignSetDTOFromJSON,
    AssignSetDTOToJSON,
    CopySetDTOFromJSON,
    CopySetDTOToJSON,
    CreateSetDTOFromJSON,
    CreateSetDTOToJSON,
    DeleteSetDTOFromJSON,
    DeleteSetDTOToJSON,
    GetUserSetsDTOFromJSON,
    GetUserSetsDTOToJSON,
    SetDTOFromJSON,
    SetDTOToJSON,
    SetDefaultSetDTOFromJSON,
    SetDefaultSetDTOToJSON,
    SetValidationDTOFromJSON,
    SetValidationDTOToJSON,
    UserDTOFromJSON,
    UserDTOToJSON,
} from '../models/index';

export interface SetAssignSetToUserRequest {
    assignSetDTO: AssignSetDTO;
}

export interface SetByUserRequest {
    getUserSetsDTO: GetUserSetsDTO;
}

export interface SetCopySetRequest {
    copySetDTO: CopySetDTO;
}

export interface SetCreateSetRequest {
    createSetDTO: CreateSetDTO;
}

export interface SetDeleteSetRequest {
    deleteSetDTO: DeleteSetDTO;
}

export interface SetGetSetByIdRequest {
    setId: string;
}

export interface SetSetDefaultSetRequest {
    setDefaultSetDTO: SetDefaultSetDTO;
}

export interface SetSetsRequest {
    onlyEditable: string;
}

export interface SetUnassignSetFromUserRequest {
    assignSetDTO: AssignSetDTO;
}

export interface SetUpdateSetRequest {
    setDTO: SetDTO;
}

export interface SetValidateRequest {
    setId: string;
}

/**
 * 
 */
export class SetApi extends runtime.BaseAPI {

    /**
     */
    async setAssignSetToUserRaw(requestParameters: SetAssignSetToUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['assignSetDTO'] == null) {
            throw new runtime.RequiredError(
                'assignSetDTO',
                'Required parameter "assignSetDTO" was null or undefined when calling setAssignSetToUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set/assign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignSetDTOToJSON(requestParameters['assignSetDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async setAssignSetToUser(requestParameters: SetAssignSetToUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.setAssignSetToUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setByUserRaw(requestParameters: SetByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SetDTO>>> {
        if (requestParameters['getUserSetsDTO'] == null) {
            throw new runtime.RequiredError(
                'getUserSetsDTO',
                'Required parameter "getUserSetsDTO" was null or undefined when calling setByUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set/byUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserSetsDTOToJSON(requestParameters['getUserSetsDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SetDTOFromJSON));
    }

    /**
     */
    async setByUser(requestParameters: SetByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SetDTO>> {
        const response = await this.setByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setCopySetRaw(requestParameters: SetCopySetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['copySetDTO'] == null) {
            throw new runtime.RequiredError(
                'copySetDTO',
                'Required parameter "copySetDTO" was null or undefined when calling setCopySet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopySetDTOToJSON(requestParameters['copySetDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setCopySet(requestParameters: SetCopySetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setCopySetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setCreateSetRaw(requestParameters: SetCreateSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDTO>> {
        if (requestParameters['createSetDTO'] == null) {
            throw new runtime.RequiredError(
                'createSetDTO',
                'Required parameter "createSetDTO" was null or undefined when calling setCreateSet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSetDTOToJSON(requestParameters['createSetDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDTOFromJSON(jsonValue));
    }

    /**
     */
    async setCreateSet(requestParameters: SetCreateSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDTO> {
        const response = await this.setCreateSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setDeleteSetRaw(requestParameters: SetDeleteSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['deleteSetDTO'] == null) {
            throw new runtime.RequiredError(
                'deleteSetDTO',
                'Required parameter "deleteSetDTO" was null or undefined when calling setDeleteSet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSetDTOToJSON(requestParameters['deleteSetDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async setDeleteSet(requestParameters: SetDeleteSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.setDeleteSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setGetDefaultSetIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/set/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async setGetDefaultSetId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setGetDefaultSetIdRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setGetSetByIdRaw(requestParameters: SetGetSetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDTO>> {
        if (requestParameters['setId'] == null) {
            throw new runtime.RequiredError(
                'setId',
                'Required parameter "setId" was null or undefined when calling setGetSetById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/set/byId/{setId}`.replace(`{${"setId"}}`, encodeURIComponent(String(requestParameters['setId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDTOFromJSON(jsonValue));
    }

    /**
     */
    async setGetSetById(requestParameters: SetGetSetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDTO> {
        const response = await this.setGetSetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setListAllSetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SetDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/set/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SetDTOFromJSON));
    }

    /**
     */
    async setListAllSets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SetDTO>> {
        const response = await this.setListAllSetsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setSetDefaultSetRaw(requestParameters: SetSetDefaultSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['setDefaultSetDTO'] == null) {
            throw new runtime.RequiredError(
                'setDefaultSetDTO',
                'Required parameter "setDefaultSetDTO" was null or undefined when calling setSetDefaultSet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set/default`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDefaultSetDTOToJSON(requestParameters['setDefaultSetDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setSetDefaultSet(requestParameters: SetSetDefaultSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setSetDefaultSetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setSetsRaw(requestParameters: SetSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SetDTO>>> {
        if (requestParameters['onlyEditable'] == null) {
            throw new runtime.RequiredError(
                'onlyEditable',
                'Required parameter "onlyEditable" was null or undefined when calling setSets().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['onlyEditable'] != null) {
            queryParameters['onlyEditable'] = requestParameters['onlyEditable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/set`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SetDTOFromJSON));
    }

    /**
     */
    async setSets(requestParameters: SetSetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SetDTO>> {
        const response = await this.setSetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setUnassignSetFromUserRaw(requestParameters: SetUnassignSetFromUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['assignSetDTO'] == null) {
            throw new runtime.RequiredError(
                'assignSetDTO',
                'Required parameter "assignSetDTO" was null or undefined when calling setUnassignSetFromUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set/unassign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignSetDTOToJSON(requestParameters['assignSetDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setUnassignSetFromUser(requestParameters: SetUnassignSetFromUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setUnassignSetFromUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setUpdateSetRaw(requestParameters: SetUpdateSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDTO>> {
        if (requestParameters['setDTO'] == null) {
            throw new runtime.RequiredError(
                'setDTO',
                'Required parameter "setDTO" was null or undefined when calling setUpdateSet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetDTOToJSON(requestParameters['setDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDTOFromJSON(jsonValue));
    }

    /**
     */
    async setUpdateSet(requestParameters: SetUpdateSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDTO> {
        const response = await this.setUpdateSetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setValidateRaw(requestParameters: SetValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetValidationDTO>> {
        if (requestParameters['setId'] == null) {
            throw new runtime.RequiredError(
                'setId',
                'Required parameter "setId" was null or undefined when calling setValidate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/set/validate/{setId}`.replace(`{${"setId"}}`, encodeURIComponent(String(requestParameters['setId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetValidationDTOFromJSON(jsonValue));
    }

    /**
     */
    async setValidate(requestParameters: SetValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetValidationDTO> {
        const response = await this.setValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
