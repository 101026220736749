/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OCRResultDTO
 */
export interface OCRResultDTO {
    /**
     * 
     * @type {string}
     * @memberof OCRResultDTO
     */
    text: string;
}

/**
 * Check if a given object implements the OCRResultDTO interface.
 */
export function instanceOfOCRResultDTO(value: object): value is OCRResultDTO {
    if (!('text' in value) || value['text'] === undefined) return false;
    return true;
}

export function OCRResultDTOFromJSON(json: any): OCRResultDTO {
    return OCRResultDTOFromJSONTyped(json, false);
}

export function OCRResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OCRResultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'],
    };
}

export function OCRResultDTOToJSON(json: any): OCRResultDTO {
    return OCRResultDTOToJSONTyped(json, false);
}

export function OCRResultDTOToJSONTyped(value?: OCRResultDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'text': value['text'],
    };
}

