import { ExerciseDTO } from '../generated';

export interface FilterProps {
  name?: string | undefined;
  bodyZoneId?: string;
}

export const filter = (exercises: Array<ExerciseDTO>, props: FilterProps) => {
  return exercises
    .filter((e) => (props.name ? e.name.toLowerCase().includes(props.name.toLowerCase()) : true))
    .filter((e) => (props.bodyZoneId ? e.mainBodyZoneId === props.bodyZoneId : true));
};
