/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDefaultSetDTO
 */
export interface SetDefaultSetDTO {
    /**
     * 
     * @type {string}
     * @memberof SetDefaultSetDTO
     */
    setId: string;
}

/**
 * Check if a given object implements the SetDefaultSetDTO interface.
 */
export function instanceOfSetDefaultSetDTO(value: object): value is SetDefaultSetDTO {
    if (!('setId' in value) || value['setId'] === undefined) return false;
    return true;
}

export function SetDefaultSetDTOFromJSON(json: any): SetDefaultSetDTO {
    return SetDefaultSetDTOFromJSONTyped(json, false);
}

export function SetDefaultSetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDefaultSetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'setId': json['setId'],
    };
}

export function SetDefaultSetDTOToJSON(json: any): SetDefaultSetDTO {
    return SetDefaultSetDTOToJSONTyped(json, false);
}

export function SetDefaultSetDTOToJSONTyped(value?: SetDefaultSetDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'setId': value['setId'],
    };
}

