/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    identityProvider: UserDTOIdentityProviderEnum;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    accountType: UserDTOAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    identityProviderToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    username: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDTO
     */
    setIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDTO
     */
    roles: Array<UserDTORolesEnum>;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    lastLogin: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    active: boolean;
}


/**
 * @export
 */
export const UserDTOIdentityProviderEnum = {
    Local: 'local',
    Google: 'google'
} as const;
export type UserDTOIdentityProviderEnum = typeof UserDTOIdentityProviderEnum[keyof typeof UserDTOIdentityProviderEnum];

/**
 * @export
 */
export const UserDTOAccountTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type UserDTOAccountTypeEnum = typeof UserDTOAccountTypeEnum[keyof typeof UserDTOAccountTypeEnum];

/**
 * @export
 */
export const UserDTORolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;
export type UserDTORolesEnum = typeof UserDTORolesEnum[keyof typeof UserDTORolesEnum];


/**
 * Check if a given object implements the UserDTO interface.
 */
export function instanceOfUserDTO(value: object): value is UserDTO {
    if (!('identityProvider' in value) || value['identityProvider'] === undefined) return false;
    if (!('accountType' in value) || value['accountType'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('setIds' in value) || value['setIds'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    if (!('lastLogin' in value) || value['lastLogin'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'identityProvider': json['identityProvider'],
        'accountType': json['accountType'],
        'identityProviderToken': json['identityProviderToken'] == null ? undefined : json['identityProviderToken'],
        'userId': json['userId'],
        'username': json['username'],
        'setIds': json['setIds'],
        'roles': json['roles'],
        'lastLogin': (new Date(json['lastLogin'])),
        'active': json['active'],
    };
}

export function UserDTOToJSON(json: any): UserDTO {
    return UserDTOToJSONTyped(json, false);
}

export function UserDTOToJSONTyped(value?: UserDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'identityProvider': value['identityProvider'],
        'accountType': value['accountType'],
        'identityProviderToken': value['identityProviderToken'],
        'userId': value['userId'],
        'username': value['username'],
        'setIds': value['setIds'],
        'roles': value['roles'],
        'lastLogin': ((value['lastLogin']).toISOString()),
        'active': value['active'],
    };
}

