import { AccessAlarms, AddAlarm, FitnessCenter, Repeat } from '@mui/icons-material';
import { ImageFromServer } from './ImageFromServer';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import { ExerciseDTO } from '../generated';

interface ExerciseProps {
  exercise: ExerciseDTO;
  onClick?: () => void;
}

export const ExerciseSmall = (props: ExerciseProps) => {
  return (
    <Grid onClick={props.onClick} style={{ margin: 4 }} container xs={12}>
      <Grid item xs={4} style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
        <Typography sx={{ marginLeft: 1 }}>{props.exercise.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <ImageFromServer sx={{ height: '8rem', width: '6rem' }} thumbnail={false} fluid={true} alt="" id={props.exercise.imageId} />
      </Grid>
      <Grid alignContent={'center'} item xs={3}>
        <Stack alignItems={'center'}>
          <Stack direction={'row'} sx={{ m: 1 }}>
            <FitnessCenter />
            <Typography sx={{ mx: 1 }}>{props.exercise.templateConfig.weight ?? 0}</Typography>
          </Stack>
          <Stack direction={'row'} sx={{ m: 1 }}>
            <Repeat />
            <Typography sx={{ mx: 1 }}>{props.exercise.templateConfig.repetitions ?? 0}</Typography>
          </Stack>
          <Stack direction={'row'} sx={{ m: 1 }}>
            {props.exercise.templateConfig.useTimer ? <AccessAlarms /> : <AddAlarm />}
            <Typography sx={{ mx: 1 }}>{props.exercise.templateConfig.time ?? 0}</Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
