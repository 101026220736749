/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExerciseLink
 */
export interface ExerciseLink {
    /**
     * 
     * @type {string}
     * @memberof ExerciseLink
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseLink
     */
    uri: string;
}

/**
 * Check if a given object implements the ExerciseLink interface.
 */
export function instanceOfExerciseLink(value: object): value is ExerciseLink {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('uri' in value) || value['uri'] === undefined) return false;
    return true;
}

export function ExerciseLinkFromJSON(json: any): ExerciseLink {
    return ExerciseLinkFromJSONTyped(json, false);
}

export function ExerciseLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExerciseLink {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'uri': json['uri'],
    };
}

export function ExerciseLinkToJSON(json: any): ExerciseLink {
    return ExerciseLinkToJSONTyped(json, false);
}

export function ExerciseLinkToJSONTyped(value?: ExerciseLink | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'uri': value['uri'],
    };
}

