import { Box, Button, Grid, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { TagIntensity } from '../TagIntensity';
import { AddCircleOutline, Close } from '@mui/icons-material';
import { modalStyle } from './modalStyle';
import { BodyZone, MuscleIntensity } from '../../generated';
import { Intensity } from '../../models/intensity';

const noZone: BodyZone = { id: 'nz', isDefaultZone: false, name: 'None', zoneTagIds: [] };

type TagEditModalProps = {
  selectedMuscleIntensities: MuscleIntensity[];
  setSelectedMuscleIntensities: (intensities: MuscleIntensity[]) => void;
  showEditButtons?: boolean;
  show: boolean;
  setShow: (show: boolean) => void;
  selectionComplete?: () => void;
};

export const TagEditModal = (props: TagEditModalProps) => {
  const { bodyZoneList, muscleTagMap } = useContext(AppContext);
  const [filterName, setFilterName] = useState<string>('');
  const [selectedBodyZone, setSelectedBodyZone] = useState<BodyZone>(noZone);
  const [allMuscleIntensities, setAllMuscleIntensities] = useState<MuscleIntensity[]>([]);
  const allMuscleTags = Array.from(muscleTagMap?.values() || []);

  useEffect(() => {
    let filteredByName = allMuscleTags;

    if (filterName !== '') {
      filteredByName = allMuscleTags.filter((mt) => mt.name.toLowerCase().includes(filterName.toLowerCase()));
    }

    if (selectedBodyZone.id !== 'nz') filteredByName = filteredByName.filter((f) => selectedBodyZone.zoneTagIds.includes(f.id));

    setAllMuscleIntensities(filteredByName.map((mt) => ({ intensity: Intensity.medium, muscleId: mt.id })));
  }, [filterName, selectedBodyZone]);

  return (
    <Modal
      open={props.show}
      onClose={() => {
        // if (reason && reason === 'backdropClick') return;
        props.setShow(false);
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <Box sx={{ my: 1 }}>
          <Stack direction={'row'}>
            <Typography>Edit Tags</Typography>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <Close onClick={() => props.setShow(false)} />
            </Box>
          </Stack>
        </Box>
        <Box sx={{ my: 1 }}>
          <TextField sx={{ mx: 1 }} label="Filter" autoFocus={true} value={filterName} onChange={(e) => setFilterName(e.target.value)} />
          <Select value={selectedBodyZone.id}>
            {[noZone, ...bodyZoneList].map((bz) => (
              <MenuItem
                key={bz.id}
                value={bz.id}
                selected={selectedBodyZone.id === bz.id}
                onClick={(e) => {
                  setSelectedBodyZone(bz);
                }}
              >
                {bz.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{ m: 1 }}
            onClick={() => {
              setFilterName('');
              setSelectedBodyZone(noZone);
            }}
          >
            Clear Filter
          </Button>
          <Box sx={{ my: 1 }}>
            {allMuscleIntensities?.map((mi) => {
              const selected = props.selectedMuscleIntensities.find((ti) => ti.muscleId === mi.muscleId);
              return (
                <TagIntensity
                  muscleIntensity={selected ?? mi}
                  editable
                  bgColor={selected ? 'rgb(40, 54, 24)' : undefined}
                  elementClicked={(e, del) => {
                    let result = props.selectedMuscleIntensities.filter((smi) => smi.muscleId !== e.muscleId);
                    if (!del) result.push(e);
                    props.setSelectedMuscleIntensities(result);
                  }}
                />
              );
            })}
          </Box>
          <Grid container>
            {props.showEditButtons ? (
              <>
                <Grid item>
                  <Button
                    onClick={() => {
                      if (props.selectionComplete) props.selectionComplete();
                      props.setShow(false);
                    }}
                  >
                    Save Selection
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      props.setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </>
            ) : undefined}

            <Grid item sx={{ display: 'flex' }} flexGrow={1} justifyContent={'flex-end'}>
              <Button
                onClick={() => {
                  props.setSelectedMuscleIntensities([]);
                }}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
