import { useContext, useRef, useState } from 'react';
import { Minimize } from '@mui/icons-material';
import { AppContext } from '../App';
import { Chip, ClickAwayListener, MenuItem, Select } from '@mui/material';
import { MuscleIntensity, MuscleIntensityIntensityEnum } from '../generated';
import { Intensity } from '../models/intensity';

interface TagIntensityProps {
  muscleIntensity: MuscleIntensity;
  bgColor?: string;
  editable?: boolean;
  elementClicked?: (bzti: MuscleIntensity, deleted?: boolean) => void;
}

export const TagIntensity = (props: TagIntensityProps) => {
  const context = useContext(AppContext);
  const enumToArray = () =>
    Object.values(Intensity)
      .filter((key) => !isNaN(Number(key)))
      .map((k) => Number(k));
  const [editMode, setEditMode] = useState<boolean | undefined>(undefined);
  const elementRef = useRef<HTMLHeadingElement | null>(null);

  return (
    <span ref={elementRef}>
      <ClickAwayListener
        onClickAway={(event) => {
          if ((event.target as any).localName === 'body') {
            event.preventDefault();
            return;
          }
          setEditMode(false);
        }}
      >
        {editMode ? (
          <Chip
            label={
              <>
                {context.muscleTagMap?.get(props.muscleIntensity.muscleId)?.name}
                {' - '}
                <Select variant="standard" sx={{ my: 1 }} value={props.muscleIntensity.intensity} displayEmpty>
                  {enumToArray()?.map((c) => (
                    <MenuItem
                      key={c}
                      value={c}
                      selected={props.muscleIntensity.intensity === c}
                      onClick={(e) => {
                        if (props.elementClicked) props.elementClicked({ ...props.muscleIntensity, intensity: c as any });
                        setEditMode(false);
                        e.stopPropagation();
                      }}
                    >
                      {Intensity[c]}
                    </MenuItem>
                  ))}
                </Select>
                <Minimize
                  sx={{ ml: 5 }}
                  onClick={(e) => {
                    if (props.elementClicked) props.elementClicked(props.muscleIntensity, true);
                    setEditMode(false);
                  }}
                />
              </>
            }
            style={{ backgroundColor: props.bgColor ?? '#606C38', fontSize: 14 }}
            onClick={() => {
              setEditMode(false);
            }}
          ></Chip>
        ) : (
          <Chip
            style={{ margin: 2, backgroundColor: props.bgColor ?? '#606C38', fontSize: 14 }}
            onClick={() => {
              if (props.editable) {
                setEditMode(!editMode);
              }
              if (props.elementClicked) props.elementClicked(props.muscleIntensity);
            }}
            label={`${context.muscleTagMap?.get(props.muscleIntensity.muscleId)?.name} - ${Intensity[props.muscleIntensity.intensity]}`}
          />
        )}
      </ClickAwayListener>
    </span>
  );
};
