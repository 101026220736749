import { useEffect, useState } from 'react';
import { muscleTagApi } from '../generated/clients';
import { MuscleTag } from '../generated';

export function useMuscleTagMap() {
  const [muscleTagList, setMuscleTagList] = useState<Array<MuscleTag>>([]);
  const [muscleTagMap, setMuscleTagMap] = useState<Map<string, MuscleTag>>(new Map<string, MuscleTag>());
  const [error, setError] = useState<string | undefined>();

  const loadMuscleTagMap = async () => {
    try {
      const muscleZoneTags = await muscleTagApi.muscleTagList();
      setMuscleTagList(muscleZoneTags);
      const bodyZoneMap = new Map<string, MuscleTag>();
      muscleZoneTags.forEach((bzt) => bodyZoneMap.set(bzt.id, bzt));
      setMuscleTagMap(bodyZoneMap);
      setError(undefined);
    } catch (e) {
      if (e instanceof Error) setError(e.message);
    }
  };

  useEffect(() => {
    loadMuscleTagMap();
  }, []);

  return [muscleTagList, muscleTagMap, error, loadMuscleTagMap] as const;
}
