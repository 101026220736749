import { useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { ImageFromFile } from '../ImageFromFile';
import { imgPreview } from '../../utils/imageRendering';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { serverOcr } from '../../utils/api-helpers';

export const TextScanModal = (props: any) => {
  const [imageFile, setImageFile] = useState<File | null>();
  const [imageBlob, setImageBlob] = useState<Blob | null>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [exerciseText, setExerciseText] = useState<string>();
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);

  return (
    <Modal
      open={props.show}
      onClose={() => {
        props.onHide(exerciseText);
      }}
    >
      <Box sx={modalStyle}>
        <Typography variant="h4">Scan Exercise Text</Typography>
        <input type="file" onChange={(e) => setImageFile((e.target as any).files ? (e.target as any).files[0] : null)}></input>
        <Stack>
          {imageFile ? (
            <>
              <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={(c) => setCrop(c)}>
                <ImageFromFile ref={imgRef} thumbnail={true} style={{ maxWidth: 300, maxHeight: 300 }} image={imageFile}></ImageFromFile>
              </ReactCrop>
              <TextField multiline value={exerciseText} rows={5} />
              <br></br>
              <Button
                onClick={async () => {
                  if (completedCrop && imgRef.current) {
                    const blob = await imgPreview(imgRef.current, completedCrop);
                    const imageFile = new File([blob], 'image.jpeg', {
                      type: blob.type,
                    });
                    setImageFile(imageFile);
                    setImageBlob(blob);
                  }
                }}
              >
                Crop
              </Button>
              &nbsp;
              <Button
                onClick={async () => {
                  if (imageBlob) {
                    console.log(imageBlob);
                    const ocrResult = await serverOcr(imageBlob);
                    setExerciseText(ocrResult.text);
                  }
                }}
              >
                Scan
              </Button>
            </>
          ) : null}
        </Stack>
      </Box>
    </Modal>
  );
};
