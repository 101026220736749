import { useState } from 'react';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { userApi } from '../../generated/clients';

export const ForgotPasswordModal = (props: any) => {
  const [email, setEmail] = useState('');

  return (
    <>
      <Modal open={props.show} onClose={() => props.setShow(false)}>
        <Box sx={modalStyle}>
          <Stack spacing={2}>
            <Typography variant="h4">Forgot your Password?</Typography>
            <Typography variant="h5">Enter your email below and press the Ok Button.</Typography>
            <TextField
              label={'Email Address'}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Stack direction={'row'}>
              <Button
                onClick={async () => {
                  await userApi.userForgotPassword({ email });
                  props.setShow(false);
                }}
              >
                Ok
              </Button>
              <Button onClick={() => props.setShow(false)}>Cancel</Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
